@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,600');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family:'Didot';
    src: url('../fonts/Didot.eot');
	src: url('../fonts/Didot.eot?#iefix') format('embedded-opentype'),
		url('../fonts/Didot.woff2') format('woff2'),
		url('../fonts/Didot.woff') format('woff'),
		url('../fonts/Didot.ttf') format('truetype'),
		url('../fonts/Didot.otf') format('opentype'),
		url('../fonts/Didot.svg#Didot') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0-10FFFF;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Light.eot');
    src: url('../fonts/Graphik-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Graphik-Light.woff2') format('woff2'),
        url('../fonts/Graphik-Light.woff') format('woff'),
        url('../fonts/Graphik-Light.ttf') format('truetype'),
        url('../fonts/Graphik-Light.svg#Graphik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Bold.eot');
    src: url('../fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Graphik-Bold.woff2') format('woff2'),
        url('../fonts/Graphik-Bold.woff') format('woff'),
        url('../fonts/Graphik-Bold.ttf') format('truetype'),
        url('../fonts/Graphik-Bold.svg#Graphik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Extralight.eot');
    src: url('../fonts/Graphik-Extralight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Graphik-Extralight.woff2') format('woff2'),
        url('../fonts/Graphik-Extralight.woff') format('woff'),
        url('../fonts/Graphik-Extralight.ttf') format('truetype'),
        url('../fonts/Graphik-Extralight.svg#Graphik-Extralight') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik';
    src: url('../fonts/Graphik-Semibold.eot');
    src: url('../fonts/Graphik-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Graphik-Semibold.woff2') format('woff2'),
        url('../fonts/Graphik-Semibold.woff') format('woff'),
        url('../fonts/Graphik-Semibold.ttf') format('truetype'),
        url('../fonts/Graphik-Semibold.svg#Graphik-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}
