.contacto {
    position: relative;
    background: url('../images/home/contacto.jpg') no-repeat center center / cover;
    font-family: $altFont;

    @include mq(md) {
        height: 63vh;
        min-height: 480px;
    }

    &__wrapper {
        @include padd;
        padding-left: 15px!important;
        padding-right: 15px!important;
        display: flex;

        @include mq(md) {
            padding-left: 50px!important;
            padding-right: 50px!important;
        }
    }

    &__data {
        display: flex;
        justify-content: flex-end;
        flex-basis: 60%;
        flex-wrap: wrap;
        font-size: 14px;
        padding-right: 15px;

        @include mq(md) {
            font-size: 21px;
        }

        a {
            display: block;
            color: $darkestGray;

            &:nth-of-type(2) {
                margin-bottom: 20px;
            }
        }
    }

    &__people {
        display: flex;
        justify-content: flex-end;
        flex-basis: 40%;
        font-size: 14px;
        line-height: 1.7;
        letter-spacing: 1px;

        @include mq(md) {
            font-size: 16px;
        }

        p {
            margin-bottom: 20px;
        }

        a {
            display: block;
            color: $darkestGray;
        }

        strong {
            font-weight: 600;
        }
    }
}
