.postulaciones {
    position: relative;
    min-height: 650px;

    &__img {
        height: 63vh;
        background: url('../images/postulaciones/fondo.jpg') no-repeat center center / cover;
        position: absolute;
        top: 0;
        left:0;
        right:0;

        @include mq(md) {
            min-height: 500px;
        }
    }

    &__intro {
        min-height: 50vh;
        position: relative;
        width: 35%;
        background: $red;
        padding: 15px;
        font-family: $altFont;
        z-index: 2;

        @include mq(md) {
            max-height: 400px;
            width: 34%;
            padding: 60px;
        }
    }

    &__title {
        color: $white;
        font-family: $quoteFont;
        font-weight: 300;
        font-size: 4vw;
        line-height: 1.5;
        letter-spacing: 1.2px;

        @include mq(md) {
            font-size: 2.8vw;
        }

        &:after {
            content: '/';
            margin-left: 10px;
        }
    }

    &__form {
        width: 100%;
        background: $fadedRed;
        padding: 30px 20px;

        @include mq(md) {
            width: 48%;
            position: absolute;
            left: 34%;
            top: 0;
            padding: 60px 40px;
        }

        &__intro {
            width: 100%;
            font-size: 18px;
            margin-bottom: 20px;

            @include mq(md){
                width: 50%;
            }
        }

        &__wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include mq(md) {
                flex-wrap: nowrap;
            }
        }

        &__col {
            border-top: 1px solid $red;
            padding-top: 15px;
            width: 100%;

            @include mq(md) {
                width: 48%;
            }

            &__title {
                text-transform: uppercase;
                font-family: $altFont;
                color: $red;
                margin: 0 0 20px;
                font-size: 17px;
                letter-spacing: 1px;
                font-weight: 200;

                &.-alt {
                    margin-top: 15px;
                    padding-top: 15px;
                    border-top: 1px solid;
                }
            }
        }

        &__group {
            padding-bottom: 15px;
        }

        &__label {
            display: block;
            font-family: $mainFont;
            font-weight: 300;
            font-size: 14px;

            &.-lang {
                font-size: 12px;
            }
        }

        &__radio {
            display: inline-block;
            margin-right: 10px;
            padding-top: 5px;

            .postulaciones__form__label {
                @include trans;
                padding-right: 36px;
                line-height: 32px;
                position: relative;
                cursor: pointer;

                &:after {
                    @include trans;
                    
                    content: '';
                    width: 18px;
                    height: 18px;
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    background: $black;
                    opacity: 0;
                }

                &:before {
                    @include trans;

                    display: block;
                    content: '';
                    width: 28px;
                    height: 28px;
                    border: 1px solid $black;
                    position: absolute;
                    right: 0;
                    top:0;
                }
            }
        }

        &__submit {
            @include trans;
            display: inline-block;
            padding: 0 20px;
            height: 40px;
            background: $mainColor;
            color: $white;
            font-family: $altFont;
            font-weight: 600;
            border: none;

            &:hover {
                padding: 0 30px;
            }
        }

        &__input {
            background: none;
            width: 100%;
            border: 1px solid $black;
            height: 28px;
            padding-left: 10px;

            & + input {
                margin-top: 10px;
            }

            &--textarea {
                height: 80px;
            }

            &--file {
                padding: 0;
                height: 30px;
            }

            &--radio {
                position: absolute;
                left:-9999px;

                &:checked {
                    & + label {
                        &:after {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
