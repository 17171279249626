.equipo {
    position: relative;
    background: url('../images/equipo/fondo.jpg') no-repeat center 0 / cover;
    min-height: 100vh;

    @include mq(md) {
        overflow: hidden;
        height: 67vh;
        min-height: 550px;
    }

    &__nav {
        position: absolute;
        z-index: 2;
        top: 60px;
        left:0;
        width: 300px;
        padding: 20px;

        @include mq(md) {
            width: 34%;
            bottom:0;
            top: 0;
            padding: 40px 0 60px 60px;
        }

        li {
            @include trans;
            margin-bottom: 10px;
            line-height: 1.2;
            cursor: pointer;

            @include mq(md) {
                margin-bottom: 5px;
                line-height: 1;
            }

            &:hover,
            &.-active {
                color: $red;
                font-weight: 600;
            }

            &.-inactive {
                color: $white;
            }
        }
    }

    &__sections {
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom: 0;
        z-index: 1;
        overflow: hidden;
    }

    &__section {
        @include trans;
        position: absolute;
        top:0;
        left:0;
        right:0;
        background: $fadedRed;
        padding: 150px 20px 20px;
        font-family: $altFont;
        opacity: 0;
        transform: translateY(-100%);
        display: flex;

        @include mq(md) {
            padding: 60px 60px 60px 35%;
        }

        &--admin {
            .equipo__list--multicol {
                @include mq(md) {
                    columns: 2;
                    width: 60%;
                }
            }
        }

        &.-active {
            opacity: 1;
            transform: translateY(0);
        }

        &__title {
            color: $black;
            font-family: $altFont;
            font-size: 4vw;
            margin: 0 0 20px;

            @include mq(md) {
                font-size: 1.3vw;
            }

            &:before {
                content: '/';
                margin-right: 10px;
            }
        }

        &__maincol {
            padding-right: 10px;
            width: 100%;
            margin-bottom: 20px;

            @include mq(md) {
                width: 20%;
                margin-bottom: 0;
            }
        }

        &__secondarycol {
            width: 100%;

            @include mq(md) {
                width: 70%;
            }
        }
    }

    &__list {

        @include mq(md) {
            flex-wrap: wrap;
            display: flex;
            display: block;
        }

        &--multicol {
            .col-xs-24 {
                display: flex;
                flex-wrap: wrap;

                @include mq(md) {
                    display: block;
                }
            }
        }


        &__item {
            font-size: 3.5vw;
            margin: 0 0 5px;
            cursor: pointer;

            @include mq(md) {
                margin: 0 0 20px;
                font-size: 1vw;
            }

            &:hover {
                font-weight: 600;
            }
        }
    }

    &__profile {
        @include trans;
        position: absolute;
        top: 50px;
        bottom: 0;
        right:0;
        left:0;
        z-index: 5;
        opacity: 0;
        transform: translateY(-100%);

        @include mq(md) {
            top: 0;
        }

        &.-open {
            opacity: 1;
            transform: translateY(0);
        }

        &__close {
            position: absolute;
            right: 10px;
            top: 20px;
            z-index: 5;
            font-size: 24px;
            background: none;
            border: none;
            width: 40px;
            height:40px;
            color: $black;

            @include mq(md) {
                right: 60px;
                top:60px;
                color: $white;
            }

            &:hover {
                color: $red;
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        &__pager {
            position: absolute;
            top: calc(70vw - 40px);
            height: 30px;
            width: 20px;
            background: none;
            border: none;
            z-index: 5;

            @include mq(md) {
                height: 60px;
                width: 40px;
                top: calc(50% - 30px);
            }

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &--prev,
            &--next {
                &:before,
                &:after {
                    content: '';
                    height: 15px;
                    width: 1px;
                    position: absolute;
                    left:20px;
                    background: $black;
                    top:0;

                    @include mq(md) {
                        height: 30px;
                    }
                }

                &:after {
                    top: 15px;

                    @include mq(md) {
                        top: 30px;
                    }
                }
            }

            &--prev{
                left: 0;

                @include mq(md) {
                    left: 20px;
                }

                &:before {
                    transform: skewX(-30deg);
                }

                &:after {
                    transform: skewX(30deg);
                }
            }

            &--next {
                right: 20px;

                &:before {
                    transform: skewX(30deg);
                }

                &:after {
                    transform: skewX(-30deg);
                }
            }
        }

        &__types {
            position: absolute;
            z-index: 2;
            top: 0;
            //bottom:0;
            left:0;
            width: 300px;
            padding: 15px;

            @include mq(md) {
                top: 0;
                padding: 60px;
            }

            li {
                @include trans;
                margin-bottom: 10px;
                line-height: 1.2;
                opacity: 0;
                position: absolute;
                top: 30px;
                left:20px;
                max-width: 200px;

                @include mq(md) {
                    top: 60px;
                    left:60px;
                    max-width: none;
                }

                &.-active {
                    color: $white;
                    opacity: 1;
                }
            }
        }

        &__person {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right:0;
            left:0;
            overflow: hidden;

            &.-active {
                display: block;
            }

            &__img {
                position: relative;
                overflow: hidden;
                padding-bottom: 70.5%;

                @include mq(md){
                    top: 0;
                    bottom: 0;
                    right:0;
                    left:0;
                    position: absolute;
                    padding-bottom: 0;
                }

                img {
                    position: absolute;
                    top:0;
                    height: 100%;
                    right: 0;

                    @include mq(md) {
                        right: auto;
                        transform: translateX(-50%);
                        left:50%;
                        height: auto;
                        width: 100%;
                    }
                }
            }

            &__item {
                display: block;
                font-size: 16px;
            }

            &__cv {
                display: flex;
                align-items: center;
                font-size: 14px;

                i {
                    font-size: 16px;
                }
            }

            &__skills {
                list-style: circle;
                padding-left: 20px;
                max-width: 300px;
                font-size: 14px;
            }

            &__name {
                font-weight: 600;
                font-size: 18px;
            }

            &__email,
            &__role {
                font-size: 16px;
                display: block;
            }

            &__data {
                z-index: 2;
                font-family: $mainFont;
                padding-top: 20px;
                background: $midGray;
                padding-left: 20px;
                padding-bottom: 20px;
                columns: 2;

                @include mq(md) {
                    background: none;
                    position: absolute;
                    left:380px;
                    padding-top: 60px;
                    padding-left: 0;
                    columns: 1;
                    padding-bottom: 0;
                }

                h3,h4 {
                    margin: 0 0 10px;
                }

                &__block {
                    margin-bottom: 15px;

                    @include mq(md) {
                        margin-bottom: 1vw;
                    }

                    &--top {
                        margin-bottom: 20px;

                        @include mq(md){
                            margin-bottom: 3vw;
                        }
                    }
                }
            }
        }
    }
}
