.nosotros {
    position: relative;
    height: auto;

    @include mq(md) {
        height: 63vh;
    }

    &__text {
        background: $fadedRed;
        padding: 20px;

        @include mq(md) {
            display: flex;
            padding: 50px;
        }

        &__main {
            font-family: $quoteFont;
            font-size: 5vw;
            line-height: 1.5;
            margin: 0 0 20px;

            br {
                display: none;
            }

            @include mq(md) {
                font-size: 2.5vw;
                margin: 0;
                flex-basis: 28%;

                br {
                    display: flex;
                }
            }
        }

        &__content {
            flex-basis: 26%;
            padding-left: 0;
            font-size: 0.9em;
            line-height: 1.4;

            @include mq(md) {
                padding-left: 95px;
            }
        }
    }
}
