.header {

    $h: &;

    height: 60px;
    position: fixed;
    padding: 10px 0;
    z-index: 1000;
    background: $white;
    width: 100%;

    @include mq(md) {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 24vh;
        min-height: 200px;
        padding: 50px 0;
    }

    &__col {
        width: 100%;
        flex-basis: 100%;
        padding: 0 30px;


        &--logo {

            @include mq(md) {
                width: 34%;
                flex-basis: 34%;
                padding: 0 0 0 50px;
            }
        }

        &--nav {
            position: absolute;
            top: 60px;
            padding: 0;
            pointer-events: none;

            @include mq(md) {
                width: 58%;
                flex-basis: 58%;
                position: static;
                padding: 0 50px;
                pointer-events: all;
            }
        }
    }

    &__logo {
        padding-top: 10px;
        width: 80%;
        min-width: 150px;

        @include mq(md) {
            padding-top: 0;
            width: 100%;
        }

        img {
            width: 100%;
        }
    }

    &__nav {
        @include trans;
        display: flex;
        justify-content: flex-start;
        font-family: $altFont;
        background: rgba(255,255,255,.8);
        padding: 20px;
        flex-wrap: wrap;
        transform: translateX(-100%);
        opacity: 0;
        pointer-events: none;

        @include mq(md) {
            background: none;
            padding: 5px 0 0 40px;
            flex-wrap: nowrap;
            justify-content: flex-end;
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;
        }

        &__col {
            flex-basis: 100%;
            display: flex;
            max-width: 200px;
            padding-left: 40px;

            @include mq(md) {
                flex-basis: 25%;
            }

            &:first-child {
                @include mq(md) {
                    flex-basis: 30%;
                }
            }

            &:last-child {
                @include mq(md) {
                    flex-basis: 20%;
                }
            }
        }

        &__list {
            &__item {
                margin-bottom: 10px;
                white-space: nowrap;

                @include mq(md) {
                    margin-bottom: 0;
                }
            }
        }

        &__link {
            color: $black;

            @include mq(md) {
                font-size: 12px;
                letter-spacing: 1px;
            }

            &:hover,
            &.-active {
                color: $red;
            }
        }

        &__language {
            display: flex;
            font-weight: 600;

            @include mq(md) {
                font-size: 12px;
            }

            &__divider {
                padding: 0 5px;
            }

            &__selector {
                cursor: pointer;
                &.-active {
                    color:$red;
                    pointer-events: none;
                    cursor: default;
                }
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 25px;
        margin: auto;
        position: absolute;
        right: 30px;
        top: 20px;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__col--nav {
            pointer-events: all;
        }

        #{$h}__nav {
            transform: translateX(0);
            opacity: 1;
            pointer-events: all;
        }
    }

    & + main {
        padding-top: 60px;

        @include mq(md) {
            padding-top: 0;
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
