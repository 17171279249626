.section-title {
    text-transform: uppercase;
    color: $black;
    font-family: $altFont;
    letter-spacing: 2px;
    font-size: 3.8vw;
    margin: 0 0 20px;
    font-weight: 200;

    @include mq(md){
        font-size: 1.35vw;
        letter-spacing: 4px;
    }

    &:before {
        content: '/';
        margin-right: 10px;
    }
}

.home {
    height: 63vh;
    min-height: 480px;

    &__hero {
        position: relative;

        &__slider {
            .slick-dots {
                bottom: -30px;
                li{
                    width: 10px;
                    height: 10px;

                    button:before {
                        color: $black;
                    }
                }
            }
        }

        &__img {
            position: relative;
            height: 37.8vh;
            margin-bottom: 25.2vh;

            @include mq(md) {
                overflow: hidden;
                margin-bottom: 0;
                height: 63vh;
            }

            img {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
                left:0;
                top:0;
            }
        }

        &__quote {
            @include trans;
            padding: 20px;
            position: absolute;
            bottom:30px;
            left:0;
            right: 0;
            background: $fadedRed;
            pointer-events: none;

            @include mq(md) {
                width: 34%;
                min-width: 380px;
                right: auto;
                top:0;
                bottom:0;
                padding: 50px;
                letter-spacing: -1px;
                max-height: 63vh;
            }

            &__text {
                font-family: $quoteFont;
                font-size: 5vw;
                line-height: 1.5;
                margin: 0;

                br {
                    display: none;
                }

                @include mq(md) {
                    font-size: 2.8vw;

                    br {
                        display: block;
                    }
                }
            }
        }
    }
}
