._spacer {
    height: 30px;
}

.areas {
    position: relative;
    background: url('../images/home/slide7.jpg') no-repeat center center / cover;
    height: 100vh;

    @include mq(md) {
        height: 63vh;
        background: url('../images/home/slide7.jpg') no-repeat center center / cover;
        min-height: 480px;
    }

    &__nav {
        height: 100%;
        position: relative;
        width: 35%;
        background: $red;
        padding: 15px;
        font-family: $altFont;
        z-index: 2;

        @include mq(md){
            width: 34%;
            padding: 50px;
        }

        &__links {
            color: $white;

            li {
                @include trans;

                display: block;
                margin-bottom: 10px;
                padding-bottom: 3px;
                font-size: 12px;
                position: relative;

                @include mq(md) {
                    font-size: 1.2vw;
                    letter-spacing: 2px;
                }

                &:before {
                    @include trans;

                    content: '';
                    width: 0;
                    height: 1px;
                    background: $white;
                    position: absolute;
                    bottom: 0;
                    left:0;
                }

                &:hover,
                &.-active {
                    cursor: pointer;
                    font-weight: 600;

                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    &__pages{
        height: 100%;
        position: absolute;
        width: 65%;
        left:35%;
        top:0;
        overflow: hidden;

        @include mq(sm){
            width: 66%;
            left:34%;
        }
    }

    &__page {
        @include trans;
        position: absolute;
        top:90px;
        left:15px;
        right:15px;
        bottom: 30px;
        opacity: 0;

        @include mq(md) {
            top:60px;
            left:60px;
            right:60px;
            bottom: 60px;
            padding-bottom: 0;
        }

        &:nth-child(1) {
            transform: translateX(-100%);
        }

        &:nth-child(2) {
            transform: translateX(100%);
        }

        &.-active {
            transform: translateX(0);
            opacity: 1;
        }

        &__wrapper {
            @include trans;
            background: $fadedRed;
            width: 100%;
            position: absolute;
            overflow-y: auto;
            overflow-x: hidden;
            opacity: 0;
            transform: translateX(-100%);
            left:0;
            top:0;
            max-height: 100%;
            min-height: 100%;

            @include mq(md){
                min-height: none;
                width: 50%;
            }

            &.-active {
                opacity: 1;
                transform: translateX(0);
            }

            &::-webkit-scrollbar-track {
            	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            	background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
            	width: 6px;
            	background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
            	background-color: $red;
            }
        }

        &__nav {
            @include trans;
            background: none;
            color: $red;
            width: 40px;
            height: 40px;
            position: absolute;
            top: calc(50% - 20px);
            border:none;
            opacity: 1;
            pointer-events: all;
            z-index: 5;

            &:focus {
                outline: none;
                box-shadow: none;
            }

            &--next {
                right:0;
            }

            &.-disabled {
                color: $midGray;
                opacity: .3;
                pointer-events: none;
            }
        }

        p, ul, dl {
            font-size: 0.9em;
        }

        ul {
            list-style: circle;
            margin-bottom: 20px;
            padding-left: 20px;
        }

        dt {
            float: left;
            margin-right: 5px;
            font-weight: 300;

            @include mq(md) {
                margin-right: 10px;
            }
        }

        dd {
            a {
                display: flex;
                align-items: center;
            }

            i {
                font-size:14px;
            }
        }
    }
}
